<script>
export default {
  data: () => ({
    active: false,
  }),
  computed: {
    classes() {
      return {
        active: this.active,
      }
    }
  },
  methods: {
    pickup() {
      this.active = true
      this.sfx.pickItem.play()
    }
  }
}
</script>
<template>
  <div :class="classes" class="box-opener" @click="pickup" @touchstart="emulateClick" @touchend="emulateClick">
    <slot v-on="$listeners" />
  </div>
</template>
<style lang="stylus">
.box-opener
  cursor pointer
</style>
