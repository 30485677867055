<script>
export default {
  data: () => ({
    locked: false,
  }),
  mounted() {
    document.addEventListener('keyup', this.keyupHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyupHandler)
  },
  methods: {
    clickHandler() {
      this.sfx.button.play()
    },
    keyupHandler(event) {
      if (event.code === 'Space' || event.code === 'Enter' || event.code === 'NumpadEnter') {
        this.$emit('click', event)
      }
    }
  },
}
</script>
<template>
  <div class="button" :class="{ disabled: locked }" v-on="$listeners" @click="clickHandler" @touchstart="emulateClick" @touchend="emulateClick">
    <!-- <slot></slot> -->
  </div>
</template>

<style lang="stylus" scoped>
 definePosition(defaultPos, activePos)
  &:before
    background-position 0 defaultPos
  &:hover, &:active
    &:before
      background-position 0 activePos

.button
  width 100%
  padding-bottom 43%
  cursor pointer
  position relative
  transform-origin center center
  animation scale-in-center .5s cubic-bezier(0.34, 1.56, 0.64, 1) both
  &:before
    content ''
    display block
    width 100%
    height 100%
    position absolute
    background-image url('~assets/buttons.png')
    background-repeat no-repeat
    background-size 100% 1200% // 每塊相隔9.1%
  &.tp // 台北抽獎
    definePosition(0%, 9.1%)
  &.sh // 上海抽獎
    definePosition(18.2%, 27.3%)
  &.go-to-play // 去抽大獎
    definePosition(36.4%, 45.5%)
  &.who-play // 誰來抽獎
    definePosition(54.6%, 63.7%)
  &.finish // 完成
    definePosition(72.8%, 81.9%)
  &.start // 抽獎開始
    definePosition(90.9%, 100%)

@keyframes scale-in-center
  0%
    transform scale(0)
    opacity .5
  100%
    transform scale(1)
    opacity 1
</style>
