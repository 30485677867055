<script>
export default {
  props: {
    current: Object
  },
  mounted() {
    // 1.2秒後自動下一場景
    this.timer = setTimeout(() => {
      this.$emit('continue')
    }, 1.2 * 1000)
    this.bgm.fadeOut()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.bgm.fadeIn()
  },
}
</script>
<template>
  <div class="view view__breaktime">
    <div class="house house-right" />
    <div class="house house-left" @click="$emit('continue')" />
  </div>
</template>

<style lang="stylus">
$transitionTime = .5s

.view__breaktime
  z-index 200
  background-image url('~assets/bg-opening.png')
  &:before
    content ''
    display block
    width 100%
    height 100%
    background-color rgba(0,0,0, .6)
    transition .5s
    will-change background-color
  .house
    position absolute
    top 0
    &:before
      content ''
      display block
      width 100%
      background-size cover
      will-change transform
    &-left
      left 0
      width 65%
      transform scaleX(1)
      backface-visibility hidden
      &:before
        padding-bottom 118%
        background-image url('~assets/frame-house.png')
    &-right
      right 0
      width 66.4%
      backface-visibility hidden
      &:before
        padding-bottom 115%
        background-image url('~assets/frame-house2.png')

.view__breaktime
  animation-name nothing !important
  .house
    &-left:before
      animation left-closing $transitionTime cubic-bezier(0.16, 1, 0.3, 1) both $transitionTime
    &-right:before
      animation right-closing $transitionTime cubic-bezier(0.16, 1, 0.3, 1) both $transitionTime

.view__breaktime.zoomOut.animated
  animation-name nothing !important
  &:before
    background-color rgba(0,0,0, 1)
  .house
    &-left:before
      animation left-opening .6s cubic-bezier(0.16, 1, 0.3, 1) both
    &-right:before
      animation right-opening .6s cubic-bezier(0.16, 1, 0.3, 1) both

@keyframes left-closing
  from
    transform translateX(-100%)
  to
    transform translateX(0%)

@keyframes right-closing
  from
    transform translateX(100%)
  to
    transform translateX(0%)

@keyframes left-opening
  from
    transform translateX(0%)
  to
    transform translateX(-100%)

@keyframes right-opening
  from
    transform translateX(0%)
  to
    transform translateX(100%)

</style>
