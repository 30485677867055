import Intro from 'views/Intro'
import Game from 'views/Game'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Intro },
  { path: '/play', component: Game },
]

const router = new VueRouter({
  routes
})

export default router