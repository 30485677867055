<script>

import { v4 as uuid } from 'uuid'

class SocketClient {
  constructor() {
    this.clientId = uuid()
    this.socket = null
    this.busy = false
    this.wasConnected = false
    this.status = '離線模式'
    this.listeners = []
    this.session = null
  }

  get connected() {
    return this.socket.readyState === 1
  }

  connect() {
    this.busy = true

    this.socket = new WebSocket(`${location.protocol.replace('http', 'ws')}//${location.hostname}:${location.port}/cloud`)

    this.socket.addEventListener('close', data => {
      this.reconnect()
    })
    this.socket.addEventListener('open', data => {
      this.status = '已連線'
      this.busy = false
      this.wasConnected = true
    })
    this.socket.addEventListener('error', () => {
      console.error('Cannot connect to Socket')
    })
    this.socket.addEventListener('message', ({ data: message }) => {
      const { type, data: { client, data } } = JSON.parse(message)
      this.session = JSON.parse(JSON.stringify(data))
      if (type === 'restore') {
        this.listeners.filter(({ type }) => type === 'restore').forEach(({ handler }) => {
          handler(data, { type })
        })
        this.status = `已從雲端還原狀態 (peer: ${client})`
      }
      if (type === 'update') {
        this.listeners.filter(({ type }) => type === 'update').forEach(({ handler }) => {
          handler(data, { type })
        })
        this.status = `已從雲端同步遊戲狀態 (peer: ${client})`
      }
      if (type === 'reset') {
        this.listeners.filter(({ type }) => type === 'reset').forEach(({ handler }) => {
          handler(data, { type })
        })
        this.status = `遊戲已重設 (peer: ${client})`
      }
    })
  }

  reconnect() {
    if (this.wasConnected) {
      this.busy = true
      this.status = '重新取得連線'
      this.socket.close()
      setTimeout(this.connect.bind(this), 1000)
    } else {
      this.busy = false
      this.status = '離線模式'
    }
  }

  async send(type, data) {
    return new Promise((resolve, reject) => {
      this.ready(res => {
        if (this.connected) {
          this.socket.send(JSON.stringify({
            type,
            data: {
              data,
              client: this.clientId,
            },
          }))
          return resolve(true)
        }
        return resolve(false)
        // return reject(new Error('Socket is not connected'))
      })
    })
  }

  async syncUp(currentSession) {
    if (JSON.stringify(this.session) !== JSON.stringify(currentSession)) {
      await this.send('save', currentSession).then(success => {
        if (success) {
          this.session = JSON.parse(JSON.stringify(currentSession))
          this.status = '已儲存'
        }
      })
    }
  }

  async reset() {
    await this.send('reset').then(success => {
      if (success) {
        this.status = '已重設'
      }
    })
  }

  ready(callback) {
    const callbackWrap = () => {
      callback(this.connected)
      this.socket.removeEventListener('open', callbackWrap)
      this.socket.removeEventListener('error', callbackWrap)
    }
    if (this.socket.readyState === 0) { // 等待連線中
      this.socket.addEventListener('open', callbackWrap)
      this.socket.addEventListener('error', callbackWrap)
    } else {
      callback()
    }
  }

  onReset(callback) {
    this.listeners.push({ type: 'reset', handler: callback })
  }

  realtime(callback) {
    this.listeners.push({ type: 'update', handler: callback })
  }

  restore(callback) {
    this.listeners.push({ type: 'restore', handler: callback })
    this.send('restore')
  }
}

const client = new SocketClient()

export { client }

export default {
  data: () => client,
  watch: {},
  created() {
    client.connect()
  },
  methods: {

  }
}
</script>

<template>
  <div class="websocket-info">
    <div class="status">
      <div v-text="status" />
      <div v-text="clientId" />
    </div>
    <div v-if="busy" class="locked" />
  </div>
</template>

<style lang="stylus">
.websocket-info
  position absolute
  opacity .4
  top 0
  left 0
  padding .5em
  z-index 9999
  font-size 12px
  .status
    position relative
    z-index 100
  .locked
    background alpha(black 0.9)
    position fixed
    left 0
    top 0
    bottom 0
    right 0
    z-index 90
</style>
