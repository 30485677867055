<template>
  <div class="view view__lanterns" :class="backgroundClass">
    <div class="full-view animated fadeInUp slow ">
      <div v-for="item in lanterns" :key="item.serial" class="game-element" :class="item.outerClass">
        <BoxOpener
          :class="item.wrapClass"
          @click.native="choose(item)"
          @touchstart="emulateClick"
          @touchend="emulateClick">
          <Lantern :item="item" :glitter="glitter" />
        </BoxOpener>
      </div>
      <div class="game-word" :class="leaveTime">
        <div class="pic-default pic" />
      </div>
    </div>
    <div v-if="secondScene" class="full-view">
      <div class="sparkle">
        <div class="pic-default pic" />
      </div>
      <div v-if="bigbang" class="bigbang" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      secondScene: false,
      bigbang: false,
      glitter: false,
      shake: false,
    }
  },
  computed: {
    pickedLantern: {
      get() { return this.$game.current.pickedLantern },
      set(item) {
        this.$game.current.pickedLantern = item
        this.$game.save() // 儲存狀態
      }
    },
    sloganClass() {
      return this.$game.current.sloganClass
    },
    lanterns() {
      return this.sloganClass.map((slogan, idx) => {
        let wrapClass = []
        let outerClass = []
        if (this.pickedLantern && this.pickedLantern.sloganClass !== slogan) wrapClass = ['animated', 'fadeOut', 'faster']
        if (this.pickedLantern && this.pickedLantern.sloganClass === slogan) outerClass = ['active', 'fadeOut', 'delay-2s']
        if (this.pickedLantern && this.pickedLantern.sloganClass === slogan && this.shake) wrapClass = [...wrapClass, 'shake-constant', 'shake-little']
        return {
          serial: idx + 1,
          outerClass,
          wrapClass,
          lanternClass: `lantern-${(idx + 1) % 3}`,
          sloganClass: slogan
        }
      })
    },
    backgroundClass() {
      if (this.secondScene) {
        return ['locked', 'rising']
      } else {
        return []
      }
    },
    leaveTime() {
      if (this.secondScene) {
        return ['animated', 'fadeOut', 'fast']
      } else {
        return []
      }
    },
  },
  watch: {
    pickedLantern: {
      immediate: true,
      handler(isPicked) {
        if (isPicked) {
          // 切換scene2
          this.secondScene = true
        }
      }
    },
    secondScene: {
      immediate: true,
      handler(secondScene) {
        if (secondScene) {
          setTimeout(() => { this.shake = true }, 400) // 先顫抖
          setTimeout(() => { this.glitter = true }, 600) // 強烈光暈
          setTimeout(() => { this.bigbang = true }, 900) // 爆炸
          setTimeout(() => this.sfx.boom.play(), 900) // 爆炸音效
          // 換場
          setTimeout(() => { this.$emit('continue') }, 1400) // 在爆炸消失之前換場
        } else {
          this.shake = false
          this.glitter = false
          this.bigbang = false
        }
      }
    }
  },
  mounted() {
    this.gameSong.lounder()
    this.gameSong.play()
    document.addEventListener('keyup', this.keyupHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyupHandler)
  },
  methods: {
    choose(item) {
      this.pickedLantern = item
      this.sfx.lanternMoving.play()
    },
    keyupHandler(event) {
      console.log(event.code)
      if (event.code === 'Space' || event.code === 'Enter' || event.code === 'NumpadEnter') {
        this.choose(this.lanterns.slice().shuffle().pop())
      }
      if (event.code === 'PageUp' || event.code === 'Numpad7') {
        this.choose(this.lanterns[0])
      }
      if (event.code === 'PageDown' || event.code === 'Numpad9') {
        this.choose(this.lanterns[1])
      }
      if (event.code === 'ArrowUp' || event.code === 'ArrowDown' || event.code === 'Numpad5') {
        this.choose(this.lanterns[2])
      }
      if (event.code === 'ArrowLeft' || event.code === 'Numpad1') {
        this.choose(this.lanterns[3])
      }
      if (event.code === 'ArrowRight' || event.code === 'Numpad3') {
        this.choose(this.lanterns[4])
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
.full-view
  width 100%
  height 100%
  position absolute
.view__lanterns
  height 100%
  background-repeat no-repeat
  &:before
    content ""
    display block
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    background-image url('~assets/bg-field.jpg')
    background-repeat no-repeat
    background-size 100%
    transform scale(2.25) translateX(-7%) translateY(-27%)
    // NOTE will-change 會使動畫效果被偷工
    will-change transform

  &.rising:before
    // easeOutQuad
    animation rising 1s 0s cubic-bezier(0.5, 1, 0.89, 1) both
  &.locked
    pointer-events none

.game-element
  position absolute
  width 24.8%
  transition 0.8s
  top 22.1%
  left 36.5%
  transform translateX(0%) translateY(0%)
  will-change transform
  &:nth-child(1)
    transform translateX(-125%) translateY(-55%)
  &:nth-child(2)
    transform translateX(125%) translateY(-55%)
  &:nth-child(3)
    transform translateX(0%) translateY(0%)
  &:nth-child(4)
    transform translateX(-125%) translateY(55%)
  &:nth-child(5)
    transform translateX(125%) translateY(55%)
  &.active // 被選中的
    transform translateX(0) translateY(-2vh)

.game-word
  position absolute
  width 43%
  left 30%
  bottom 7%
  .pic
    padding-bottom 17.7%
    background-image url('~assets/word-lantern.png')

// 光圈
.sparkle
  position absolute
  width 88%
  left 6%
  opacity 0
  animation pulse .5s .6s 1 cubic-bezier(0, 0.55, 0.45, 1) alternate both
  will-change transform, opacity
  .pic
    padding-bottom 83%
    background-image url('~assets/img-shine.png')

@keyframes opening
  from
    transform scale(2.5) translateX(-7%) translateY(0%)
  to
    transform scale(2.25) translateX(-7%) translateY(-27%)
@keyframes rising
  from
    transform scale(2.25) translateX(-7%) translateY(-27%)
  to
    transform scale(3) translateX(33%) translateY(25%)
@keyframes pulse
  0%
    opacity 0
    transform scale(0.01) rotate(30deg)
  100%
    opacity 0.6
    transform scale(1.5) rotate(-60deg)
</style>

<style lang="less" scoped>
@speed: 1;
@scale: 0.4;

.bigbang {
  animation: magic-zoom 2.4s * @speed;
  animation-fill-mode: forwards;
  will-change: transform;
  overflow: visible;
  pointer-events: none;

  z-index: 200;

  overflow: visible;
  position: absolute;
  left: calc(~"(100% - 40vw)/2");
  top: calc(~"(100% - 40vw)/2");
  height: 100vw * @scale;
  width: 100vw * @scale;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: auto;
    right: auto;
    background: white;
    z-index: 200;
    pointer-events: none;

    width: 100%;
    height: 100%;
    border-radius: 50%;

    will-change: transform;

    animation-name: shake-little, fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 100ms, 0.8s * @speed;
    animation-delay: 0s, 1.7s * @speed;
    animation-timing-function: ease-in-out, cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-iteration-count: infinite, 1;
    box-shadow: 0 0 10vw * @scale 5vw * @scale #ffffff, 0 0 20vw * @scale 5vw * @scale#FFEA94, 0 0 30vw * @scale 5vw * @scale #fff6d2;
  }

  @keyframes magic-zoom {

    from,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0%,
    5% {
      transform: scale(0.01 / @scale);
      opacity: 0.6;
    }

    50%,
    100% {
      transform: scale(1.25 / @scale);
      opacity: 1;
    }
  }
}</style>
