<template>
  <div :class="{playing}" class="app" @click="playMusic" @touchstart="playMusic">
    <AppcacheInfo />
    <SocketConnector />
    <router-view />
  </div>
</template>
<script>
import 'inobounce'
import AppcacheInfo from 'lib/AppcacheInfo'
import SocketConnector from 'lib/SocketConnector.vue'
import router from 'router'
import { backgroundMusic } from 'lib/SoundManager'
export default {
  router,
  components: {
    AppcacheInfo,
    SocketConnector,
  },
  data: () => ({
    playing: false,
  }),
  methods: {
    playMusic() {
      this.playing = true
      // backgroundMusic && backgroundMusic.loaded && !backgroundMusic.playing && backgroundMusic.play()
    }
  }
}
</script>

<style lang="less">
@import '~reset-css';
// @import (css) url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700');

@aspect-condition: ~"screen and (min-aspect-ratio: 1024/768)";

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  position: fixed;
  overflow: hidden;
  user-select: none;

  font-size: 1.56vw;
  @media @aspect-condition {
    font-size: 2.08vh;
  }
}

body {
  font-family: '微軟正黑體', 'Microsoft JhengHei', Helvetica, Arial, 'sans-serif', 'Heiti TC', '微軟雅黑', 'Microsoft YaHei', '宋體',
    'Adobe 繁黑體 Std', 'AdobeFanHeitiStd-Bold', 'Adobe Heiti Std', 'AdobeHeitiStd-Regular', '新細明體', 'PMingLiU';
  background-color: #000;
  color: white;

  display: fixed;

  width: 100vw;
  height: 100vh;
  // -webkit-overflow-scrolling: touch;
}
a {
  text-decoration: none;
}

.app {
  outline: 1px solid white;
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.view {
  height: 100%;
  width: 100%;
  margin: auto;

  position: relative;

  max-height: 768/1024 * 100vw;
  max-width: 1024/768 * 100vh;

  background-position: center;
  background-size: cover;
  background-color: #fff;
  &--restricted {
    overflow: hidden;
    transform: translate(0);
  }
  &.zoomOut {
    pointer-events: none;
  }
  &.v-enter-active {
    .stage__button {
      display: none;
    }
  }
}

.spotlight {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="stylus">
// pic default setting
.pic-default
  width 100%
  background-position center center
  background-repeat no-repeat
  background-size contain

// 動畫delay
.animate__delay-200ms
  animation-delay .2s
.animate__delay-500ms
  animation-delay .5s
</style>
