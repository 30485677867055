module.exports =
[
  {
    'no': 1,
    'rank': 1,
    'amount': '特獎',
    'message': '特獎資格！',
    'special': true,
    'rookie': false
  },
  {
    'no': 2,
    'rank': 2,
    'amount': '特獎',
    'message': '特獎資格！',
    'special': true,
    'rookie': false
  },
  {
    'no': 3,
    'rank': 3,
    'amount': '特獎',
    'message': '特獎資格！',
    'special': true,
    'rookie': false
  },
  {
    'no': 4,
    'rank': 4,
    'amount': 28000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 5,
    'rank': 5,
    'amount': 27000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 6,
    'rank': 6,
    'amount': 26000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 7,
    'rank': 6,
    'amount': 26000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 8,
    'rank': 7,
    'amount': 25000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 9,
    'rank': 7,
    'amount': 25000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 10,
    'rank': 7,
    'amount': 25000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 11,
    'rank': 8,
    'amount': 24000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 12,
    'rank': 8,
    'amount': 24000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 13,
    'rank': 8,
    'amount': 24000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 14,
    'rank': 8,
    'amount': 24000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 15,
    'rank': 8,
    'amount': 24000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 16,
    'rank': 9,
    'amount': 23000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 17,
    'rank': 9,
    'amount': 23000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 18,
    'rank': 9,
    'amount': 23000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 19,
    'rank': 9,
    'amount': 23000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 20,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 21,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 22,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 23,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 24,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 25,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 26,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 27,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 28,
    'rank': 10,
    'amount': 22000,
    'message': '普通獎項',
    'special': false,
    'rookie': false
  },
  {
    'no': 29,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 30,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 31,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 32,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 33,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 34,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 35,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 36,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 37,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 38,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 39,
    'rank': 11,
    'amount': 21000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 40,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 41,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 42,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 43,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 44,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 45,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 46,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 47,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 48,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 49,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 50,
    'rank': 12,
    'amount': 20000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 51,
    'rank': 13,
    'amount': 19000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 52,
    'rank': 13,
    'amount': 19000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 53,
    'rank': 13,
    'amount': 19000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 54,
    'rank': 13,
    'amount': 19000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 55,
    'rank': 13,
    'amount': 19000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 56,
    'rank': 14,
    'amount': 18000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 57,
    'rank': 14,
    'amount': 18000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 58,
    'rank': 14,
    'amount': 18000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 59,
    'rank': 14,
    'amount': 18000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 60,
    'rank': 15,
    'amount': 17000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 61,
    'rank': 15,
    'amount': 17000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 62,
    'rank': 15,
    'amount': 17000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 63,
    'rank': 15,
    'amount': 17000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 64,
    'rank': 16,
    'amount': 16000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 65,
    'rank': 16,
    'amount': 16000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 66,
    'rank': 16,
    'amount': 16000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 67,
    'rank': 16,
    'amount': 16000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 68,
    'rank': 17,
    'amount': 15000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 69,
    'rank': 17,
    'amount': 15000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 70,
    'rank': 17,
    'amount': 15000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 71,
    'rank': 17,
    'amount': 15000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 72,
    'rank': 18,
    'amount': 14000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 73,
    'rank': 18,
    'amount': 14000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 74,
    'rank': 18,
    'amount': 14000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 75,
    'rank': 18,
    'amount': 14000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 76,
    'rank': 19,
    'amount': 13000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 77,
    'rank': 19,
    'amount': 13000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 78,
    'rank': 19,
    'amount': 13000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 79,
    'rank': 20,
    'amount': 12000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 80,
    'rank': 20,
    'amount': 12000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 81,
    'rank': 21,
    'amount': 11000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 82,
    'rank': 21,
    'amount': 11000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 83,
    'rank': 21,
    'amount': 11000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 84,
    'rank': 22,
    'amount': 10000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 85,
    'rank': 22,
    'amount': 10000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
  {
    'no': 86,
    'rank': 22,
    'amount': 10000,
    'message': '普通獎項',
    'special': false,
    'rookie': true
  },
]
