<script>
import NProgress from 'nprogress'
import appCache from 'lib/appcache'
export default {
  data: ()  => ({
    appCache
  }),
  watch: {
    'appCache.progress'(progress){
      if(progress > 0 && progress < 1){
        NProgress.set(progress)
      }else{
        NProgress.done()
      }
    }
  }
}
</script>

<template>
  <div class="appcache-info" v-text="appCache.message"></div>
</template>

<style lang="less">
@import (inline) '~nprogress/nprogress.css';

.appcache-info{
  position: absolute;
  opacity: 0.4;
  top: 0;
  right: 0;
  padding: .5em;
  z-index: 9999;
  font-size: 12px;
}

#nprogress {
  .spinner {
    display: none;
  }
  .bar {
    background: #ffffff;
    box-shadow: 0 0 0.5em 3.2px #00aeff, inset 0 0 0.1em 0.15em #00aeff;
    height: 0.5em;
  }
  .peg {
    opacity: 1;
    transform: none;
    box-shadow: 0 0 0.8em 0.4em #b80cff, inset 0 0 0.8em * 0.1 0.4em * 0.1 #b80cff;
    background: white;
    border-radius: 50%;
    width: 10px;
  }
}
</style>