/*
  延遲輔助工具

  假設使用者正在縮放視窗，系統會不斷發出 resize 事件
  但我們不希望在這過程中一直執行更新，而是希望當使用者縮放完成並「停止」動作後才執行更新
  所以會設定一個緩衝時間，例如 500ms 都不再接收到 resize 事件，才執行更新動作

  Example (Callback Style)

  defer.call(this, 'resizeUpdate', 500, () => {
    this.update()
  })

  Example (Promise Style)

  defer.call(this, 'resizeUpdate', 500).then(() => {
    this.update()
  })

*/

function defer(timer, timeout, callback) {
  if (!timeout) {
    clearTimeout(this[`delayer_${timer}_timer`])
    clearTimeout(this['delayer_' + timer])
    return
  }
  if (typeof callback === 'function') {
    return deferSync.call(this, timer, timeout, callback)
  }
  return new Promise((resolve, reject) => {
    if (this[`delayer_${timer}_timer`]) {
      clearTimeout(this[`delayer_${timer}_timer`])
      if (typeof this[`delayer_${timer}_onabort`] == 'function') {
        this[`delayer_${timer}_onabort`]('Defer action aborted')
      }
      delete this[`delayer_${timer}_onabort`]
      delete this[`delayer_${timer}_timer`]
    }
    this[`delayer_${timer}_onabort`] = reject
    this[`delayer_${timer}_timer`] = setTimeout(() => {
      resolve()
    }, timeout)
  })
}

function deferSync(timer, timeout, callback) {
  clearTimeout(this['delayer_' + timer])
  this['delayer_' + timer] = setTimeout(callback, timeout)
}

export default defer
