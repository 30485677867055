<script>
import ViewIntro from 'views/Intro'
import ViewLottery from 'views/Lottery'
import ViewDisplayPlayer from 'views/DisplayPlayer'
import ViewShowtime from 'views/Showtime'
import ViewBreaktime from 'views/Breaktime'
import ViewPick from 'views/PickPlayer'
import ViewPalace from 'views/Palace'
import ViewMessage from 'views/Message'
import ViewWinners from 'views/Winners'
import appCache from 'lib/appcache'
import { client as socketClient } from 'lib/SocketConnector.vue'
import GameModule from 'lib/GameModule.js'
import defer from 'lib/defer.js'

export default {
  components: {
    ViewIntro,
    ViewLottery,
    ViewShowtime,
    ViewBreaktime,
    ViewPick,
    ViewPalace,
    ViewMessage,
    ViewDisplayPlayer,
    ViewWinners
  },
  data: () => ({
    appCache,
    current: {
      initialized: false,
      view: '',
      history: [],
      players: [],
      playerGroups: [],
      prizes: [],
      player: null,
      prize: null,
      message: null,
      error: null,
      breakMessage: null,
      pickedLantern: null,
      pickedAnimal: null,
      sloganClass: [],
      animalClass: [],
      round: 1,
    },
    show: {
      winners: false
    },
  }),
  computed: {
    config: () => require('config/app'),
    prizes() { // 所有獎項
      return this.current.prizes.slice()
    },
    players() { // 這回合玩家
      const index = this.current.round - 1
      return this.playerGroups[index] || [] // 防呆
    },
    remainingPrizes() { // 這回合可抽的剩餘獎品
      const remain = this.current.prizes
        .filter(({ no }) => !this.historyPrizeIds.includes(no))
        .shuffle()

      if (this.isRookieRound) { // 新人限定獎項
        return remain.filter(({ rookie }) => rookie)
      } else {
        return remain
      }
    },
    remainingPlayers() { // 這回合剩餘的玩家
      return this.players.filter(({ no }) => !this.historyPlayerIds.includes(no))
    },
    history() {
      return this.current.history
    },
    historyPlayerIds() {
      return this.history.map(({ player: { no } }) => no)
    },
    historyPrizeIds() {
      return this.history.map(({ prize: { no } }) => no)
    },
    player() { // 目前玩家
      return this.current.player
    },
    currentRound() {
      return this.current.round
    },
    currentView() { // 當前遊戲畫面
      if (!this.appCache.ready) {
        return 'ViewIntro'
      }
      if (this.current.message || this.current.error) {
        return 'ViewMessage'
      }
      switch (this.current.view) {
        case 'ready':
          return 'ViewLottery'
        case 'show':
          return 'ViewShowtime'
        case 'break':
          return 'ViewBreaktime'
        case 'pick':
          return 'ViewPick'
        case 'player':
          return 'ViewDisplayPlayer'
        default:
          return 'ViewBreaktime'
      }
    },
    nextPlayer() { // 下一個玩家
      return this.remainingPlayers.slice().shuffle().pop()
    },
    nextPrize() { // 下一個獎項
      return this.remainingPrizes.slice().shuffle().pop()
    },
    playedCount() {
      return this.history.length
    },
    playerNo() { // 玩家出場序號
      return Math.min(this.playedCount + 1, this.playersTotal)
    },
    playersTotal() { // 總人數
      return this.current.players.length
    },
    playerGroups() { // 這次遊戲的分組
      return this.current.playerGroups
    },
    randomPlayerGroups() { // 參賽者分組
      const splitGroup = function(array, parts) {
        const result = []
        for (let i = parts; i > 0; i--) {
          result.push(array.splice(0, Math.ceil(array.length / i)))
        }
        return result
      }
      const rookieRound = this.current.players.filter(player => player.group === 'A').shuffle()
      const groups = splitGroup(this.current.players.filter(player => player.group === 'B').shuffle(), 3)
      const [first, ...remain] = groups
      return [
        first,
        rookieRound,
        ...remain
      ]
    },
    isOver() { // 遊戲結束
      return this.current.initialized && (this.history.length === this.current.players.length)
    },
    isRookieRound() { // 新人組
      return this.currentRound === 2
    },
  },
  watch: {
    // WARNING 請勿監測狀態來觸發 save，容易會造成 echo 導致狀態錯亂
    'current.view'(view) {
      switch (view) {
        case 'ready': // 抽獎時間
          this.bgm.stop()
          break
        case 'show': // 開獎
          break
        case 'player': // 顯示抽獎人
          this.bgm.play()
          this.bgm.lounder(0.3)
          this.gameSong.stop()
          break
        // case 'pick': // 休息過場
        //   this.bgm.play()
        //   this.bgm.lounder(0.3)
        //   this.gameSong.stop()
        //   break
      }
    }
  },
  created() {
    GameModule.instance = this
    this.restore()
    // 即時從雲端更新狀態
    socketClient.realtime(current => {
      if (current) {
        this.$set(this, 'current', current)
        this.$router.push('/play')
      }
    })
    socketClient.onReset(current => {
      this.$router.push('/')
    })
  },
  updated() {
    GameModule.instance = this
  },
  beforeDestroy() {
    GameModule.instance = null
  },
  methods: {
    keepHistory() {
      if (this.player && this.prize) {
        throw '玩家尚未抽出獎項'
      }
      this.history.push({
        player: this.current.player,
        prize: this.current.prize
      })
    },
    save() {
      if (this.current.initialized) {
        defer.call(this, 'save', 80, () => {
          const newState = JSON.stringify(this.current)
          if (this.current.player === undefined) {
            alert('ERROR')
            return
          }
          if (localStorage[this.config.appId] !== newState) {
            localStorage[this.config.appId] = newState
            // 狀態同步到雲端
            socketClient.syncUp(this.current)
          }
        })
      }
    },
    reset({ force } = {}) {
      delete localStorage[this.config.appId]
      this.$set(this.current, 'prizes', require('config/prizes').slice(0))
      this.$set(this.current, 'players', require('config/players').slice(0))
      this.$set(this.current, 'history', [])
      this.$set(this.current, 'player', null)
      this.$set(this.current, 'prize', null)
      this.$set(this.current, 'message', null)
      this.$set(this.current, 'error', null)
      this.$set(this.current, 'view', 'break')
      this.$set(this.current, 'breakMessage', null)
      this.$set(this.current, 'pickedLantern', null)
      this.$set(this.current, 'pickedAnimal', null)
      this.$set(this.current, 'round', 1)
      this.current.initialized = true
      this.validate() // 點名
      this.makePlayerGroup() // 分組
      this.randomSlogan() // 產生標語
      this.randomAnimal() // 產生動物
      this.save()
      if (force) {
        defer.call(this, 'reset', 200, () => {
          socketClient.reset()
        })
      }
    },
    restore() {
      socketClient.ready(() => {
        if (socketClient.connected) {
          // 嘗試從伺服器還原狀態
          socketClient.restore(current => {
            if (!current) {
              return this.reset()
            }
            this.$set(this, 'current', current)
            this.current.error = null
            this.current.message = null
            this.current.initialized = true
          })
        } else {
          // 還原單機的狀態
          try {
            this.$set(this, 'current', JSON.parse(localStorage[this.config.appId]))
            this.current.error = null
            this.current.message = null
            this.current.initialized = true
          } catch (e) {
            this.reset()
          }
        }
        // if (!this.player) {
        //   // 遊戲開始第一次，自動選好選手(不用break過場)
        //   this.displayPlayer()
        // }
      })

      this.validate()
    },
    validate() {
      if (
        this.current.players.length !== this.current.prizes.filter(p => p && !p.joker).length
      ) {
        this.current.error = '獎項與參賽者配置數量不符'
      }
    },
    makePlayerGroup() {
      this.current.playerGroups = this.randomPlayerGroups
    },
    breaktime(message) {
      this.current.player = null
      this.current.prize = null
      this.current.pickedLantern = null
      this.randomSlogan()
      this.current.breakMessage = message
      this.current.view = 'break'
      this.current.pickedAnimal = null
      this.randomAnimal()
      if (this.remainingPlayers.length === 0) {
        this.current.round++
      }
    },
    hello() {
      if (this.players.length) {
        this.current.player = this.nextPlayer
        this.current.prize = null
      }
    },
    displayPlayer() {
      this.hello()
      this.current.view = 'player'
    },
    showtime() {
      // 選出一個獎
      this.current.prize = this.nextPrize
      this.current.view = 'show'
    },
    next() {
      switch (this.current.view) {
        case 'ready':
          this.showtime()
          break
        case 'show':
          this.keepHistory() // 開獎時將結果存到 hisotry
          this.breaktime()
          break
        case 'player':
          // 顯示寶箱
          this.current.view = 'ready'
          break
        case 'break':
          this.displayPlayer()
          break
        case 'pick':
          this.displayPlayer()
          break
      }
      this.save()
    },
    burnTest() {
      if (this.current.players.length - this.history.length > 2) {
        this.next()
        setTimeout(() => this.burnTest(), 800)
      }
    },
    superdry() {
      while (this.playersTotal - this.playerNo > 2) {
        this.next()
      }
    },
    randomSlogan() {
      const sloganQuantity = 24
      const sloganGroup = []
      for (let i = 0; i < sloganQuantity; i++) {
        sloganGroup.push(`slogan-${i + 1}`)
      }
      sloganGroup.shuffle()
      this.current.sloganClass = sloganGroup.slice(0, 5)
    },
    randomAnimal() {
      const className = []
      for (let i = 1; i <= 5; i++) {
        className.push(`animal-${i}`)
      }
      this.current.animalClass = className.shuffle()
    }
  }
}
</script>

<template>
  <div :class="`round-${currentRound}`" class="view view__game">
    <transition enter-active-class="fadeIn animated faster" leave-active-class="zoomOut animated faster" @after-enter="$emit('tabChange')">
      <component :is="currentView" :current="current" :game="this" v-bind="{ current }" @continue="next" />
    </transition>
    <transition enter-active-class="fadeIn animated faster" leave-active-class="zoomOut animated faster">
      <ViewWinners v-if="isOver || show.winners" :current="current" :game="this" @continue="show.winners=false" />
    </transition>
    <div class="remain" style="cursor: pointer;" @click="show.winners=true">
      <span v-if="currentRound">Stage #{{ currentRound }}</span>
      <span v-if="current.player" class="current-player-name">{{ current.player.name }}</span>
      <span v-else>Breaktime</span>
      <span class="progress">( {{ playerNo }} / {{ playersTotal }})</span>
    </div>
  </div>
</template>
<style lang="less">
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/csshake/1.5.3/csshake.min.css');
.view__game {
  height: 100%;
  .view {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
  }
  .remain {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5em;
    z-index: 20;
    background: rgba(0, 0, 0, 0.5);
    span {
      margin-right: 1em;
    }
  }
}
</style>
