module.exports =
[
  {
    'no': 1,
    'name': 'Eugene Wang',
    'group': 'B'
  },
  {
    'no': 2,
    'name': 'Sarah Yang',
    'group': 'B'
  },
  {
    'no': 3,
    'name': 'Jessica Liu',
    'group': 'B'
  },
  {
    'no': 4,
    'name': 'Max Chiang',
    'group': 'B'
  },
  {
    'no': 5,
    'name': 'Rachel Tsai',
    'group': 'B'
  },
  {
    'no': 6,
    'name': 'Eve Liu',
    'group': 'B'
  },
  {
    'no': 7,
    'name': 'Peggy Wang',
    'group': 'B'
  },
  {
    'no': 8,
    'name': 'Jie Peng',
    'group': 'B'
  },
  {
    'no': 9,
    'name': 'Jocelyn Cheng',
    'group': 'B'
  },
  {
    'no': 10,
    'name': 'Rhino Shi',
    'group': 'B'
  },
  {
    'no': 11,
    'name': 'Aaron Huang',
    'group': 'B'
  },
  {
    'no': 12,
    'name': 'Frank Hung',
    'group': 'B'
  },
  {
    'no': 13,
    'name': 'Fanny Yeh',
    'group': 'B'
  },
  {
    'no': 14,
    'name': 'Taylor Chen',
    'group': 'B'
  },
  {
    'no': 15,
    'name': 'James Nieh',
    'group': 'B'
  },
  {
    'no': 16,
    'name': 'Raymond Lin',
    'group': 'B'
  },
  {
    'no': 17,
    'name': 'Brandy Chen',
    'group': 'B'
  },
  {
    'no': 18,
    'name': 'Tina Su',
    'group': 'B'
  },
  {
    'no': 19,
    'name': 'Mel Liang',
    'group': 'B'
  },
  {
    'no': 20,
    'name': 'Claire Wang',
    'group': 'B'
  },
  {
    'no': 21,
    'name': 'Samuel Cheng',
    'group': 'B'
  },
  {
    'no': 22,
    'name': 'Kevin Yang',
    'group': 'B'
  },
  {
    'no': 23,
    'name': 'Lackneets Chang',
    'group': 'B'
  },
  {
    'no': 24,
    'name': 'Alan Chen',
    'group': 'B'
  },
  {
    'no': 25,
    'name': 'Annel Wang',
    'group': 'B'
  },
  {
    'no': 26,
    'name': 'Alpha Chang',
    'group': 'B'
  },
  {
    'no': 27,
    'name': 'Yuki Wang',
    'group': 'B'
  },
  {
    'no': 28,
    'name': 'Andrew Chen',
    'group': 'B'
  },
  {
    'no': 29,
    'name': 'Bono Tseng',
    'group': 'B'
  },
  {
    'no': 30,
    'name': 'Felicia Ji',
    'group': 'B'
  },
  {
    'no': 31,
    'name': 'Alison Tsai',
    'group': 'B'
  },
  {
    'no': 32,
    'name': 'Abbie Wu',
    'group': 'B'
  },
  {
    'no': 33,
    'name': 'Richard Lee',
    'group': 'B'
  },
  {
    'no': 34,
    'name': 'Angel Hsieh',
    'group': 'B'
  },
  {
    'no': 35,
    'name': 'Jason Zhu',
    'group': 'B'
  },
  {
    'no': 36,
    'name': 'Wade Lin',
    'group': 'B'
  },
  {
    'no': 37,
    'name': 'PH Chang',
    'group': 'B'
  },
  {
    'no': 38,
    'name': 'Jacky Huang',
    'group': 'B'
  },
  {
    'no': 39,
    'name': 'Joe Cheng',
    'group': 'B'
  },
  {
    'no': 40,
    'name': 'Jean Chen',
    'group': 'B'
  },
  {
    'no': 41,
    'name': 'Billy Liu',
    'group': 'B'
  },
  {
    'no': 42,
    'name': 'Sam Kuo',
    'group': 'B'
  },
  {
    'no': 43,
    'name': 'Emma Hsu',
    'group': 'B'
  },
  {
    'no': 44,
    'name': 'Cara Liu',
    'group': 'B'
  },
  {
    'no': 45,
    'name': 'Dennis Chan',
    'group': 'B'
  },
  {
    'no': 46,
    'name': 'Zack Chou',
    'group': 'B'
  },
  {
    'no': 47,
    'name': 'Leanne Hsu',
    'group': 'B'
  },
  {
    'no': 48,
    'name': 'Irene Wang',
    'group': 'B'
  },
  {
    'no': 49,
    'name': 'Winnie Yang',
    'group': 'B'
  },
  {
    'no': 50,
    'name': 'Kelly Zhou',
    'group': 'B'
  },
  {
    'no': 51,
    'name': 'Daming Lo',
    'group': 'B'
  },
  {
    'no': 52,
    'name': 'Danny Chen',
    'group': 'B'
  },
  {
    'no': 53,
    'name': 'Ivy Liao',
    'group': 'B'
  },
  {
    'no': 54,
    'name': 'Vivi Lu',
    'group': 'B'
  },
  {
    'no': 55,
    'name': 'Miao Lin',
    'group': 'B'
  },
  {
    'no': 56,
    'name': 'Dennis Chung',
    'group': 'B'
  },
  {
    'no': 57,
    'name': 'Howard Liu',
    'group': 'B'
  },
  {
    'no': 58,
    'name': 'Mark Hsieh',
    'group': 'B'
  },
  {
    'no': 59,
    'name': 'Bess Lin',
    'group': 'B'
  },
  {
    'no': 60,
    'name': 'Binni Hu',
    'group': 'B'
  },
  {
    'no': 61,
    'name': 'Cindy Lee',
    'group': 'B'
  },
  {
    'no': 62,
    'name': 'Emily Chiou',
    'group': 'B'
  },
  {
    'no': 63,
    'name': 'Monica Wu',
    'group': 'B'
  },
  {
    'no': 64,
    'name': 'Jenna Yu',
    'group': 'B'
  },
  {
    'no': 65,
    'name': 'Jack Chang',
    'group': 'B'
  },
  {
    'no': 66,
    'name': 'Mandy Lin',
    'group': 'B'
  },
  {
    'no': 67,
    'name': 'Wendy Huang',
    'group': 'B'
  },
  {
    'no': 68,
    'name': 'Young Zhang',
    'group': 'B'
  },
  {
    'no': 69,
    'name': 'David Yeh',
    'group': 'B'
  },
  {
    'no': 70,
    'name': 'Qianqian Wang',
    'group': 'B'
  },
  {
    'no': 71,
    'name': 'Doris Chen',
    'group': 'B'
  },
  {
    'no': 72,
    'name': 'Sharon Ho',
    'group': 'B'
  },
  {
    'no': 73,
    'name': 'Jamie Wu',
    'group': 'B'
  },
  {
    'no': 74,
    'name': 'Ashley Yao',
    'group': 'B'
  },
  {
    'no': 75,
    'name': 'Bing Han',
    'group': 'B'
  },
  {
    'no': 76,
    'name': 'Sharon Chen',
    'group': 'B'
  },
  {
    'no': 77,
    'name': 'Teresa Hsien',
    'group': 'B'
  },
  {
    'no': 78,
    'name': 'Susie Li',
    'group': 'B'
  },
  {
    'no': 79,
    'name': 'Joyce Lee',
    'group': 'B'
  },
  {
    'no': 80,
    'name': 'Russell Wu',
    'group': 'B'
  },
  {
    'no': 81,
    'name': 'Abby Xiong',
    'group': 'B'
  },
  {
    'no': 82,
    'name': 'Winson Chen',
    'group': 'B'
  },
  {
    'no': 83,
    'name': 'Rebecca Cheng',
    'group': 'B'
  },
  {
    'no': 84,
    'name': 'Scott William ',
    'group': 'B'
  },
  {
    'no': 85,
    'name': 'Belle Chang',
    'group': 'A'
  },
  {
    'no': 86,
    'name': 'Chris Hung',
    'group': 'B'
  }
]
