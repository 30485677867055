<script>

export default {
  props: {
    current: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    locked: true,
  }),
  computed: {
    boxType() {
      if (!this.current.prize) {
        return ''
      }
      if (this.current.prize.special) {
        // 特獎資格
        return 'box--special'
      } else if (this.current.prize.gold) {
        // 最後大獎
        return 'box--gold'
      } else {
        return 'box--normal'
      }
    },
    useSong() {
      return 'gameSong'
    },
  },
  watch: {
    'current.prize': {
      immediate: true,
      handler() {
        if (this.current.prize) {
          this.playSound()
        }
      }
    }
  },
  beforeDestroy() {
    this[this.useSong].stop()
  },
  methods: {
    playSound() {
      setTimeout(() => this[this.useSong].silenter(), 100)
      setTimeout(() => {
        // 待改
        switch (this.boxType) {
          case 'box--special':
            this.sfx.special.play()
            break
          case 'box--gold':
            this.sfx.special.play()
            break
          case 'box--normal':
            this.sfx.normal.play()
            break
        }
      }, 1000)
      setTimeout(() => {
        if (this.boxType === 'box--normal') {
          this.sfx.victory.play()
        } else {
          this.sfx.specialVictory.play()
        }
      }, 1500)
      setTimeout(() => {
        this.locked = false
      }, 2300)
    }
  },
}
</script>

<template>
  <div v-if="current.prize" class="view view__showtime">
    <div class="award-title">
      <!-- 獲得特獎資格 -->
      <template v-if="current.prize.special">
        <div class="pic-default pic-special" />
      </template>
      <template v-else>
        <div class="pic-default pic" />
        <div class="award-num">
          {{ current.prize.amount|currency }}
        </div>
      </template>
    </div>

    <div class="btn-wrap">
      <Button class="finish" :class="{locked}" @click="$emit('continue')" />
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.view__showtime
  height 100%
  background-image url('~assets/bg-award.png')
  background-size cover
  background-position 0 0
  background-repeat no-repeat
  &.special
    background-image url('~assets/bg-award-premium.png')

.btn-wrap
  position absolute
  bottom 6%
  left 50%
  transform translateX(-50%)
  width 31%

.award-title
  position relative
  width 65.7%
  margin 4.7% auto 0
  .pic
    padding-bottom 36.4%
    background-image url('~assets/sign-regular.png')
  .pic-special
    padding-bottom 36.4%
    background-image url('~assets/sign-special.png')
// 終局牌子樣式
.award-title-special
  position relative
  width 65.7%
  margin 4.7% auto 0
  .pic
    padding-bottom 36.4%
    background-image url('~assets/sign-regular.png')

.award-title, .award-title-special
  .award-num
    position absolute
    color #fff
    display flex
    justify-content center
    width 48%
    top 35%
    left 27%
    font-weight bold
    font-size 5em
    -webkit-text-stroke .03em #604129
    font-family 'Noto Sans', sans-serif

.coins
  position absolute
  width 100%
  bottom 0
  .pic
    padding-bottom 36.88%
    background-image url('~assets/img-coins.png')
</style>
