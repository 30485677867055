import "lib/appcache";
import "lib/shuffle";
import touchHandlers from "lib/touchHandlers";
import Vue from "vue";
import App from "App";
import components from "components";
import GameModule from 'lib/GameModule.js'
import { backgroundMusic, soundEffects, gameSong } from "lib/SoundManager";

Vue.mixin({
  components,
  computed: {
    supportTouch: () => "ontouchstart" in document.documentElement,
    sounds: () => soundEffects,
    sfx: () => soundEffects,
    bgm: () => backgroundMusic,
    gameSong: () => gameSong,
    $game: () => GameModule.instance,
    $envProduction: () => process.env.NODE_ENV === 'production',
    touchHandlers
  },
  methods: {
    emulateClick(e) {
      const touch = e.changedTouches[0] || e.touches[0];
      const rect = e.target.getBoundingClientRect();
      const inside =
        touch.clientX >= rect.x - 40 &&
        touch.clientX <= rect.x + rect.width + 40 &&
        touch.clientY >= rect.y - 50 &&
        touch.clientY <= rect.y + rect.height + 50;

      if (e.type == "touchstart") {
        this.__touchstart = true;
      }
      if (e.type == "touchend" && this.__touchstart) {
        this.__touchstart = false;
        // https://stackoverflow.com/a/24665924
        // https://medium.com/frochu/touch-and-mouse-together-76fb69114c04
        if (inside) {
          this.$el.click();
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
  },
  filters: {
    // prettier-ignore
    currency: function(value, digits = 0) {
      if(value == null){ return 'N/A' }
      if(digits === '~0'){ digits = Math.abs(value) < 1 ? 1 : 0}
      return (+value).toFixed(digits).split(/\./).map((p, i) => i==0 ? p.replace(/\B(?=(\d{3})+\b)/g, ",") : p).join('.')
    }
  }
});

window.app = new Vue({
  el: "#app",
  render: h => h(App)
});
