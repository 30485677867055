<template>
  <div>
    <div v-if="glitter" class="glitter-effect" />
    <div class="element-box" :class="`element-${item.serial}`">
      <!-- <div class="glowing" :class="`glowing-${item.serial}`" /> -->
      <div class="lantern" :class="item.lanternClass" />
      <div class="slogan-box">
        <div class="sprite-slogan" :class="item.sloganClass" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {
          serial: 1,
          lanternClass: 'lantern-1',
          sloganClass: 'slogan-1'
        }
      }
    },
    glitter: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="sass" scoped>
@import '~assets/sprite-slogans.css'
.lantern
  background-repeat: no-repeat
  background-size: contain
  background-position: center

.lantern
  position: relative
  width: 100%
  padding-bottom: 123%
  background-image: url('~assets/lantern1.png')
  &.lantern-1
    background-image: url('~assets/lantern1.png')
  &.lantern-2
    background-image: url('~assets/lantern2.png')
  &.lantern-3
    background-image: url('~assets/lantern3.png')

.glowing
  position: absolute
  top: calc((100% - 12vw)/2)
  left: calc((100% - 12vw)/2)
  width: 12vw
  height: 12vw
  border-radius: 50%
  box-shadow: 0 0 180px #ffff5e
  filter: blur(50px)
  animation: glowing infinite alternate linear
  will-change: transform, opacity, filter
  &:before
    content: ''
    position: absolute
    top: calc((100% - 12vw)/2)
    left: calc((100% - 12vw)/2)
    width: 12vw
    height: 12vw
    border-radius: 50%
    background: #fff
    transition: .5s
    transform: scale(.9)
    box-shadow: 0 0 160px #ffff5e

.slogan-box
  position: absolute
  width: 38.5%
  top: 17.2%
  left: calc((100% - 38.5%)/2)
  .sprite-slogan
    width: 100%
    padding-bottom: 160%
    height: 0
.glitter-effect
  position: absolute
  top: calc((100% - 35vw)/2)
  left: calc((100% - 50vw)/2)
  width: 50vw
  height: 35vw
  border-radius: 50%
  background-color: rgba(255,255,255, .2)
  filter: blur(50px)
  opacity: 0
  animation: glowing infinite .5s alternate linear both
  will-change: opacity, transform

@for $i from 1 through 5
  $random: (random() + 0.5) + s
  $delay: $i * -.5s
  .glowing-#{$i}
    animation-duration: $random
    animation-delay: $delay
    will-change: opacity, transform
  .element-#{$i}
    animation: lantern-rise 5s infinite alternate linear
    animation-delay: $delay
    will-change: opacity, transform

@keyframes lantern-rise
  0%
    transform: translate(0, 0) rotate(0deg)
  30%
    opacity: 0.95
    transform: translate(20px, -10px) rotate(10deg)
  50%
    transform: translate(20px, -10px) rotate(-10deg)
  70%
    opacity: .98
    transform: translate(10px, 0px) rotate(10deg)
  90%
    opacity: 1
    transform: translate(10px, -20px) rotate(5deg)
  100%
    transform: translate(0, -15px) rotate(-10deg)

@keyframes glowing
  0%
    opacity: 0
    transform: scale(0.6)
  100%
    opacity: 1
    transform: scale(1.05)

</style>
