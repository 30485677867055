<template>
  <div class="view view__player">
    <DecoLanterns />
    <Firefly />

    <div class="name-box">
      <div class="animated flipInX animate__delay-500ms">
        <div class="pic-default pic" />
        <div v-if="current.player" class="name">
          {{ current.player.name }}
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <Button class="go-to-play" @click="$emit('continue')" />
    </div>
  </div>
</template>

<script>
import DecoLanterns from 'components/DecoLanterns'
import Firefly from 'components/Firefly'

export default {
  components: {
    DecoLanterns,
    Firefly,
  },
  props: {
    current: Object
  },
  mounted() {
    setTimeout(() => {
      this.sfx.welcome.play()
    }, 1000)
  },
}
</script>

<style lang="stylus" scoped>
.view__player
  position relative
  height 100%
  background-size cover
  background-image url('~assets/bg-opening.png')
  &:before
    content ''
    position absolute
    width 100%
    height 100%
    background-color rgba(0, 0, 0, 0.6)
  .name-box
    position absolute
    width 76.6%
    top 41.4%
    left 50%
    transform translateX(-50%)
    .pic
      padding-bottom 36%
      background-image url('~assets/img-reel.png')
    .name
      position absolute
      text-align center
      width 100%
      font-size 5vw
      font-weight bold
      color #6b1900
      top 23%
      left 50%
      transform translateX(-50%)
  .btn-wrap
    position absolute
    left 50%
    bottom 10%
    transform translateX(-50%)
    width 22%
    .button
      animation-delay 2s

</style>
