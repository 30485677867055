<script>
export default {
  props: {
    current: Object,
  }
}
</script>
<template>
  <div class="view view__message stage">
    <div class="dj neon neon--flamingo" v-if="current.error">錯誤</div>
    <div class="dj neon neon--flamingo" v-else-if="current.message">訊息</div>
    <div class="neon-box neon-box--flamingo">
      <div class="board neon-box neon-box--midnight">
        <div class="bigband neon neon--flamingo spotlight">
          <slot>
            <div style="font-size: 0.5em">{{current.error || current.message}}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
 .view__message{
  padding: 2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
 }
 .dj{
    margin-bottom: 0.5em;
 }
</style>