<script>
export default {
  data() {
    return {
      code: '',
      showModal: false,
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
      this.code = ''
    },
    submit() {
      const code = this.code
      switch (String(code).toLowerCase()) {
        case 'restart':
          this.$game.reset({ force: true })
          this.$game.$router.push('/')
          this.$emit('continue')
          break
        case 'crazy':
          this.$game.burnTest()
          this.$emit('continue')
          break
        case 'final':
          this.$game.superdry()
          this.$emit('continue')
          break
      }
    },
    findRank(data) {
      return this.$game.history.findIndex(({ player }) => player.no === data.no) + 1
    },
  }
}
</script>
<template>
  <div class="view view__winners">
    <div v-if="showModal" class="modal animated bounceIn">
      <div class="dialog">
        <span style="margin-right: 5px;">pwd:</span> <input v-model="code" type="text" autofocus @keyup.enter="submit">
      </div>
      <div class="actions">
        <div class="button submit" @click="submit">
          確定
        </div>
        <div class="button" @click="closeModal">
          取消
        </div>
      </div>
    </div>

    <div class="cheat-button" @click="openModal" />
    <div class="winner-list">
      <div class="winner-list__content">
        <table>
          <thead>
            <tr>
              <td>順位</td>
              <td>姓名</td>
              <td>得獎金額 (台幣)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in $game.history" :key="row.player.name">
              <td>
                <span># {{ findRank(row.player) }}</span>
              </td>
              <td>{{ row.player.name }}</td>
              <td>
                <div v-if="row.prize.special">
                  {{ row.prize.message }}
                </div>
                <div v-else>
                  ${{ row.prize.amount|currency }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="btn-wrap">
      <Button v-if="!$game.isOver" class="finish" style="font-size: 1.2em;" @click="$emit('continue')">
        繼續
      </Button>
    </div>
  </div>
</template>

<style lang="less">
.view__winners{
  background-color: #000;
  background-image: url('~assets/bg-opening.png');
  z-index: 1000;
  .cheat-button{
    width: 15em;
    height: 15em;
    position: absolute;
    top: 2em;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.winner-list{
  @scale: 10em;
  overflow: hidden;
  font-size: @scale;
  &__content{
    font-family: sans-serif, '微軟正黑體';
    font-weight: lighter;
    background: fade(white, 95%);
    font-weight: bold;
    color: #333;
    position: absolute;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch;
    top: 10em/@scale;
    left: 5em/@scale;
    right: 5em/@scale;
    bottom: 8em/@scale;
    padding: 1em/@scale 3em/@scale;
    border-radius: .5em/@scale;
    table{
      width: 100%;
      font-size: 0.15em;
      thead{
        color: #aaaaaa;
      }
      tr{
        padding: .25em;
      }
      td:nth-child(1){
        width: 10%;
        padding: .25em;
        text-align: center;
      }
      td:nth-child(n+2){
        width: 45%;
        padding: .25em;
        text-align: center;
      }
    }
  }
}
</style>

<style lang="stylus" scoped>
.btn-wrap
  width 25%
  margin auto
  position absolute
  left 0
  right 0
  bottom 2%

.modal
  position absolute
  left calc((100% - 400px)/ 2)
  top calc((100% - 145px)/ 2)
  background-color #fff
  width 400px
  border-radius 5px
  z-index 10
  padding 20px
  box-shadow 0px 1px 1px #6d696e
  color #333
  .dialog, .actions
    display flex
    align-items center
    justify-content center
    margin 15px 0
  input
    border 1px solid #ddd
    appearance none
    border-radius 3px
    height 30px
    padding 0 5px
  .button
    padding 7px 12px
    background-color #999
    color #fff
    border-radius 5px
    cursor pointer
    &.submit
      background-color #4455ad
      margin-right 15px
</style>
