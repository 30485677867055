<script>
import Pizzicato from 'pizzicato'
export default {
  data: () => ({
    loadedSounds: []
  }),
  beforeDestroy() {
    this.loadedSounds.forEach(s => s && s.stop())
  },
  methods: {
    loadSound(options, callback) {
      const newSound = new Pizzicato.Sound(options, () => {
        // this.loadedSounds.splice(this.loadedSounds.indexOf(newSound), 1);
        callback && callback(newSound)
      })
      this.loadedSounds.push(newSound)
      return newSound
    }
  },
}

const soundTracksInstances = {}
const soundTracks = []
class SoundTracks {
  constructor(tracks, callback) {
    this.tracks = []
    this.tracksSrc = tracks.slice(0)
    this.tracksSrc.forEach((url) => {
      const newTrack = new Pizzicato.Sound(url, () => {
        this.tracks.push(newTrack)
        // soundTracks.push(newTrack);
        this.tracksSrc = this.tracksSrc.filter(u => u != url)
        this.ready && callback && callback(this)
      })
    })
  }

  play(volume) {
    if (this.tracks.length) {
      this.stop()
      const track = this.tracks
        .slice(0)
        .shuffle()
        .pop()

      if (volume) {
        track.volume = volume
      }
      track.play()
    }
    return this
  }

  stop() {
    this.tracks.forEach(t => t && t.stop())
    return this
  }

  get ready() {
    return this.tracksSrc.length === 0
  }

  get progress() {
    return this.tracks.length / (this.tracks.length + this.tracksSrc.length)
  }
}

const backgroundMusicVolumeMax = 0.25
const backgroundMusicVolumeMin = 0.1
const backgroundMusic = new Pizzicato.Sound(
  {
    source: 'file',
    options: {
      path: require('assets/bgm/Magical-Storytime.mp3'),
      loop: true,
      release: 1.5,
      attack: 1.5,
      volume: backgroundMusicVolumeMax
    }
  },
  () => {
    backgroundMusic.loaded = true
  }
)

// backgroundMusic.lowPass = new Pizzicato.Effects.LowPassFilter({
//   frequency: 20000,
//   peak: 10
// })
// backgroundMusic.addEffect(backgroundMusic.lowPass)

const gameSong = new Pizzicato.Sound(
  {
    source: 'file',
    options: {
      path: require('assets/bgm/chinese-new-year-celebration.mp3'),
      loop: true,
      release: 1.5,
      attack: 1.5,
      volume: backgroundMusicVolumeMax
    }
  },
  () => {
    gameSong.loaded = true
  }
)

// gameSong.lowPass = new Pizzicato.Effects.LowPassFilter({
//   frequency: 20000,
//   peak: 10
// })
// gameSong.addEffect(gameSong.lowPass)

// function fader(pass, freq) {
//   console.log('fade', pass,  freq)
//   if (pass.frequency <= freq && pass.frequency / freq < 1) {
//     pass.frequency *= 2
//     setTimeout(() => fader(pass, freq), 100)
//   } else {
//     pass.frequency = freq
//   }
//   if (pass.frequency >= freq && pass.frequency / freq > 1) {
//     pass.frequency /= 2
//     setTimeout(() => fader(pass, freq), 100)
//   } else {
//     pass.frequency = freq
//   }
// }

const actions = {
  fadeIn() {
    // fader(this.lowPass, 20000)
    console.warn('音樂的低通過濾器目前沒有安裝')
  },
  fadeOut() {
    // fader(this.lowPass, 400)
    console.warn('音樂的低通過濾器目前沒有安裝')
  },
  setVolume(volume) {
    volume = volume * backgroundMusicVolumeMax
    if (this.volume > volume) {
      this.silenter(volume)
    }
    if (this.volume < volume) {
      this.lounder(volume)
    }
  },
  mute() {
    this.setVolume(0)
  },
  silenter(volume = backgroundMusicVolumeMin) {
    clearTimeout(this.volumeTransition)
    if (this.volume >= volume) {
      if (Math.abs(this.volume - volume) > 0.01) {
        this.volume = volume
      } else {
        this.volume -= (backgroundMusicVolumeMax - volume) / 10
        this.volumeTransition = setTimeout(() => this.silenter(volume), 100)
      }
    }
  },
  lounder(volume = backgroundMusicVolumeMax) {
    clearTimeout(this.volumeTransition)
    if (this.volume <= volume) {
      if (Math.abs(this.volume - volume) > 0.01) {
        this.volume = volume
      } else {
        this.volume += (volume - backgroundMusicVolumeMin) / 10
        this.volumeTransition = setTimeout(() => this.lounder(volume), 100)
      }
    }
  }
}

Object.assign(backgroundMusic, actions)
Object.assign(gameSong, actions)

window.backgroundMusic = backgroundMusic
window.gameSong = gameSong

const withDefaultVolume = (file, volume = 0.5) => {
  return {
    source: 'file',
    options: {
      path: file,
      volume
    }
  }
}

const soundEffects = {
  welcome: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/bonus.mp3'), 0.30)
  ]),
  pickItem: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/153322.mp3'), 0.7)
  ]),
  lanternMoving: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/magical-light-moving.mp3'), 0.5)
  ]),
  normal: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/magical-coin-win-1936.mp3'), 0.5)
  ]),
  special: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/magical-coin-win-1936.mp3'), 0.5)
  ]),
  victory: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/victory.mp3'), 0.25),
    withDefaultVolume(require('assets/bgm/sound/victory-2.mp3'), 0.25)
  ]),
  specialVictory: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/special-victory2.mp3'), 0.4)
  ]),
  boom: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/140849_1931452-lq.mp3'), 0.4)
  ]),
  button: new SoundTracks([
    withDefaultVolume(require('assets/bgm/sound/shooting.mp3'), 1.0)
  ]),
}

const attachEmptySound = () => {
  let sineWave = null
  // https://paulbakaus.com/tutorials/html5/web-audio-on-ios/
  window.addEventListener(
    'touchstart',
    () => {
      if (sineWave) {
        return
      }
      sineWave = new Pizzicato.Sound({
        source: 'wave',
        options: {
          frequency: 440
        }
      })
      sineWave.volume = 0.0
      sineWave.play()
    },
    false
  )
}

attachEmptySound()

export { SoundTracks }
export { backgroundMusic, soundEffects, gameSong }
</script>
