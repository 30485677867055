export default () => {
  return {
    touchstart(event) {
      event.target.__touchstartOrigin = Array.from(event.touches).map(
        touch => ({
          x: touch.pageX,
          y: touch.pageY
        })
      );
    },
    touchmove(event) {
      if (event.target.__touchstartOrigin) {
        let offsets = [];
        Array.from(event.touches).forEach((touch, i) => {
          if (event.target.__touchstartOrigin[i]) {
            offsets.push({
              x: touch.pageX - event.target.__touchstartOrigin[i].x,
              y: touch.pageY - event.target.__touchstartOrigin[i].y
            });
          }
        });

        const evt = new CustomEvent("touchdrag", {
          offsets,
          stopDrag() {
            event.target.__touchstartOrigin = null;
          }
        });
        console.log(event.target, event, evt);
        event.target.dispatchEvent(evt);
      }
    },
    touchend(event) {
      event.target.__touchstartOrigin = null;
    },
    touchdrag(event) {
      console.log(event);
    }
  };
};
