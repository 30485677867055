<template>
  <div class="element-box" :style="animal.style">
    <div class="animal" :class="animal.name" />
    <div class="sparkle" :class="{animation: animal.sparkle}">
      <div class="pic-default pic" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    animal: {
      type: Object,
      default: function() {
        return {
          name: 'animal-1',
          sparkle: null,
          style: '',
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.animal
  width 100%
  padding-bottom 104%
  background-repeat no-repeat
  background-size contain
  background-position center
  &-1
    background-image url('~assets/pig.png')
    transform rotate(-27.04deg)
  &-2
    background-image url('~assets/tiger.png')
    transform rotate(0deg)
  &-3
    background-image url('~assets/rabbit.png')
    transform rotate(-21.85deg)
  &-4
    background-image url('~assets/chicken.png')
    transform rotate(22.56deg)
  &-5
    background-image url('~assets/ox.png')
    transform rotate(18.12deg)
.element-box
  will-change transform
  animation spin 1.5s infinite linear alternate both
.sparkle
  position absolute
  width 100%
  top 0
  left 0
  opacity 0
  will-change transform, opacity
  &.animation
    animation sparkle 1s .5s linear both
  .pic
    padding-bottom 100%
    background-image url('~assets/img-shine.png')

@keyframes spin
  from
    transform rotate(15deg)
  to
    transform rotate(-20deg)
@keyframes sparkle
  0%
    opacity 0
    transform scale(.5)
  85%
    opacity .6
  100%
    opacity .9
    transform scale(10)
</style>