<template>
  <div class="view view__night">
    <div class="entrance">
      <div class="palace animated slideInUp">
        <div class="pic-default pic" />
      </div>
      <DecoLanterns v-if="showDeco" hideLight />
      <div class="clouds animated slideInUp delay-3s">
        <div class="cloud default">
          <div class="pic-default pic" />
        </div>
        <div class="cloud rotate">
          <div>
            <div class="pic-default pic" />
          </div>
        </div>
      </div>
      <div v-if="appCache.ready" class="btn-wrap">
        <router-link class="link" to="/play">
          <Button class="start" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import appCache from 'lib/appcache'
import DecoLanterns from 'components/DecoLanterns'

export default {
  components: {
    DecoLanterns,
  },
  data: () => ({
    appCache,
    showDeco: false,
  }),
  mounted() {
    setTimeout(() => {
      this.showDeco = true
    }, 1000)
    this.bgm.stop()
  },
}
</script>

<style lang="stylus" scoped>
.entrance
  position relative
  height 100%
  background rgba(0,0,0,0.6)
  overflow hidden
.view__night
  background-image url('~assets/bg-opening.png')
  background-size 100%
.palace
  position absolute
  width 78%
  left calc((100% - 78%)/ 2)
  bottom -1.1%
  .pic
    padding-bottom 72.7%
    background-image url('~assets/palace.png')
.clouds
  position absolute
  width 100%
  height 100%
  bottom -1.1%
  left 0
  .cloud
    position absolute
    width 29.2%
    bottom 0
    transition .5s
    animation cloud .8s infinite alternate linear
    &.default
      left 3.6%
    &.rotate
      right 3.6%
      > div
        transform rotateY(-180deg)
    .pic
      padding-bottom 48.85%
      background-image url('~assets/img-cloud.png')
.btn-wrap
  position absolute
  width 22.2%
  left 50%
  bottom 7%
  transform translateX(-50%)
  .button
    animation-delay 4s

@keyframes cloud
  from
    transform translateY(15%)
  to
    transform translateY(0)
</style>
