<template>
  <div class="view view_pond">
    <Firefly :quantity="15" />
    <div class="btn-wrap">
      <Button class="who-play" @click="$emit('continue')" />
    </div>
  </div>
</template>

<script>
import Firefly from 'components/Firefly'

export default {
  components: {
    Firefly,
  },
}
</script>

<style lang="stylus" scoped>
.view_pond
  width 100%
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-image url('~assets/bg-who.jpg')
.btn-wrap
  position absolute
  width 31.7%
  top 50%
  left 50%
  transform translate(-50%, -50%)
  .button
    animation-delay 1s

</style>
