<template>
  <div class="deco-group">
    <template v-if="!hideCurtain">
      <div class="deco-curtain left animated slideInLeft animate__delay-200ms">
        <div class="pic-default pic" />
      </div>
      <div class="deco-curtain right animated slideInRight animate__delay-200ms">
        <div class="pic-default pic" />
      </div>
    </template>
    <div v-if="!hideLight" class="deco-light animated bounceInDown animate__delay-500ms">
      <div class="pic-default pic" />
    </div>
    <div class="deco-lantern animated slideInLeft" :class="{'delay-1s': !hideCurtain || !hideLight }">
      <div class="lantern-box left-side">
        <div class="pic-default pic" />
      </div>
    </div>
    <div class="deco-lantern animated slideInRight" :class="{'delay-1s': !hideCurtain || !hideLight }">
      <div class="lantern-box right-side">
        <div>
          <div class="pic-default pic" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideLight: {
      type: Boolean,
      default: false,
    },
    hideCurtain: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="stylus" scoped>
.deco-group
  position absolute
  width 100%
  height 100%
.deco-curtain
  position absolute
  top 0
  width 16.7%
  &.left
    left 0
    .pic
      padding-bottom 152%
      background-image url('~assets/img-curtain-left.png')
  &.right
    right 0
    .pic
      padding-bottom 152%
      background-image url('~assets/img-curtain-right.png')
.deco-light
  position absolute
  width 95.5%
  right 0
  top 0
  .pic
    padding-bottom 14.3%
    background-image url('~assets/img-light.png')
.deco-lantern
  position absolute
  width 100%
  height 100%
  .lantern-box
    position absolute
    width 19.6%
    top -4.5%
    &.left-side
      left 8.4%
      animation swingL .8s infinite alternate linear
    &.right-side
      right 8.4%
      animation swingR .8s infinite alternate linear
      > div
        transform rotateY(180deg)
  .pic
    padding-bottom 179%
    background-image url('~assets/img-lantern.png')

@keyframes swingL
  0%
    transform rotate(0deg)
  100%
    transform rotate(-15deg)
@keyframes swingR
  0%
    transform rotate(0deg)
  100%
    transform rotate(15deg)
</style>