import * as OfflinePluginRuntime from 'offline-plugin/runtime'

class ApplicationCacheHelper {
  constructor() {
    this.ready = true
    this.message = '啟動中'
    this.events = []
    this.progress = 0

    if (!navigator.serviceWorker.controller || navigator.serviceWorker.controller.state !== 'activated') {
      this.message = '遊戲安裝中'
    } else {
      this.message = '正在檢查更新'
      OfflinePluginRuntime.update()
    }

    navigator.serviceWorker.ready.then(sw => {
      console.log('ServiceWorker', sw)
      this.message = '已就緒'
    })

    OfflinePluginRuntime.install({
      onInstalled: () => {
        this.message = '遊戲為最新版本'
        this.ready = true
      },
      onUpdating: (args) => {
        this.message = '更新中'
        this.ready = false
      },
      onUpdateReady: (args) => {
        OfflinePluginRuntime.applyUpdate()
        this.message = '預備更新'
      },
      onUpdateFailed: () => {
        this.message = '更新失敗'
        this.ready = false
      },
      onUpdated: () => {
        this.message = '遊戲為最新版本'
        location.reload()
      }
    })
  }

  get offline() {
    return !navigator.onLine
  }

  get online() {
    return navigator.onLine
  }
}

export default new ApplicationCacheHelper()
